// Core var
:root {
    --primaryBG: linear-gradient(270deg, #A79765 0%, #ffffff 51.55%, #DABC6F 100%);;
    --primary: #2E4369;
    --btn_border_radius: 0;
    --btn_font_color: #ffffff;
  }

$primary_font: -apple - system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans - serif;

$primary_color: var(--primary);
$primary_color_BG: var(--primaryBG);
$btn_border_radius: var(--btn_border_radius);
$btn_font_color: var(--btn_font_color);
$danger_color: #d9363e;
$success_color: #4caf50;
$success_dark: darken($success_color, 10%);
$danger_dark: darken($danger_color, 10%);

$loader_bg: #fff;
$site_layout_background: #fff;
$min_layout_height: 100vh;
$logo_height: 64px;
$sidebar_width: 280px;
$sidebar_height: calc(100vh - 64px);
$scroll_light_color: #e9e9e9;
$scroll_dark_color: #b5b8bb;
$scroll_hover_color: #93999e;
$ag_alter_color: #f8f8f8;

$widget_padding_horizontal: 15px;
$widget_padding_vertical: 10px;
$counter_widget_icon_font: 45px;
$counter_widget_name_font: 16px;
$counter_widget_count_font: 14px;
$counter_widget_button_font: 12px;
