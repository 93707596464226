.dashboard{
	padding-top: 0;
	.important_note{
		.ant-typography-danger{
			color: #fd2637;
		}
	}
	.widget_wrapper {
		.ant-card-body {
			padding: 15px 0 0;
			background: #F9FBFF;
			border: 1px solid #C9DAE7;
			box-sizing: border-box;
			img {
				width: 50px;
				height: 50px;
			}
		}
		.ant-col.ant-col-16, .ant-col.ant-col-8 {
			padding-left: 12px;
			padding-right: 12px;
			display: flex;
			align-items: center;
		}
		.footer_wrapper{
			background: #DEEAF2;
			padding: 7px;
			margin-top: 20px;
			font-size: $counter_widget_count_font;
			font-weight: 400;
			.counter__widget__button{
				font-size: $counter_widget_count_font;
				color: #999;
			}
			.counter__widget__button_cs_active {
				color: black;
			}
		}
		.text_wrapper {
			margin-left: 10px;
			font-size: 18px;
			line-height: 18px;
			font-weight: bold;
			letter-spacing: 0.035em;
			color: #4E4E4E;
		}
		.count_wrapper {
			font-weight: 900;
			font-size: 4.5vw;
			line-height: 4.5vw;
			text-transform: uppercase;
			color: #4B4B4B;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.main_buttton_wrapper {
		text-align: center;
		.buttton_wrapper_1 {
			width: 138px;
			height: 44px;
			font-size: 15px;
			line-height: 15px;
			margin-right: 10px;
			background: #4FC6E0;
			border-radius: 66px;
			color: white;
		}
		.buttton_wrapper_2 {
			width: 148px;
			height: 44px;
			font-size: 15px;
			line-height: 15px;
			margin-right: 10px;
		}
	}
}