.ant-menu-dark{
    .ant-menu-submenu-selected{
        .ant-menu-item-selected{
            svg path{

                fill:  #4FC6E0;
            }
            span{
            color: #4FC6E0;
            }
        }
    }
}

.ant-menu-root > li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected {
    border-left: 5px solid #37778a;
}

// ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
//     background: #2E4369;
// }

span.ant-menu-title-content {
    color: white;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub, .ant-layout-sider {
    background: transparent;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #2E4369;
}

.ant-menu-root > li.ant-menu-item,
.ant-menu-root > li.ant-menu-submenu {
    border-bottom: 1px solid rgba(255,255,255,0.2);
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 31px;
}
.ant-menu-root > li.ant-menu-submenu-open {
    padding-bottom: 7px;
}
.ant-layout-sider-collapsed .ant-menu-root > li.ant-menu-submenu-open {
    padding-bottom: 0.02px;
}
.ant-menu-inline .ant-menu-submenu-title {
    margin: 0;
    padding-top: 4px;
    padding-bottom: 4px;
}
.ant-menu-root > li.ant-menu-submenu.ant-menu-submenu-inline{border-left: 0px;}

.ant-menu-submenu-selected.ant-menu-submenu {    
    .ant-menu-submenu-title{
    span{
    color: #4FC6E0;
    }
    img{
        filter: inherit;
    }
}
}
.ant-menu-submenu {
    .ant-menu-submenu-title{
    img{
    filter: brightness(0) invert(1);
    }
    }
}