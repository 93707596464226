.change_pass_body {
    padding: 0px;
    .ant-modal-content{
        border-radius: 5px;
        .ant-modal-close-x{
            width: 47px;
    height: 47px;
    color: #fff;
    line-height: 50px;
        }
    }
    .main__change_pass_wrapper {
        flex: 1;
    }
    .ant-modal-body {
        padding: 0px;
        font-size: 14px;
        line-height: 1.5715;
        word-wrap: break-word;
    }
    .modal-header {
        height: 48px;
        background: #2E4369;
        border-radius: 5px;
        color: white;
        font-family: 'Verdana';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .change_pass_body_inner{
        padding: 20px 30px 0px;
        .ant-col{
            img{
                width: 100%;
            }
        }
    }
}