.viewETR{
    .ant-drawer-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    table{
        padding: 0;
        margin: 1rem 0 3rem;
        width: 100%;
        text-align: left;
        td, th{
            padding: 10px;
            border: 1px solid $viewPrimaryBorder;
            vertical-align: top;
            font-size: .8rem;
            line-height: 1.2rem;
            font-weight: 700;
        }
        td{
            background-color: $viewPrimaryBG;
            color: $viewPrimaryheaderText;
        }
        th{
            font-weight: normal;
            color: $viewPrimaryColor;
            a{
                text-decoration: underline;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}