@import "antd";
@import "skin/default";
@import "core";

// INCLUDE SCSS FOR INDIVIDUAL COMPONENTS

@import "formulize.scss";

// INCLUDE SCSS FOR INDIVIDUAL PAGES

// @import "form";
@import "helper";
@import "variables";
@import "pages/Originator/login";
@import "pages/Originator/profile";
@import "pages/Originator/change_pass";
@import "pages/Originator/crdebtor";
@import "pages/Dashboard/etr";
@import "pages/Originator/trade_floor";
@import "pages/Originator/sidear";

@import "pages/TradeFloor/viewETR.scss";
