.image_card_wrapper {
    width: 380px;
    height: 365px;
    position: relative;
    text-align: center;
    border: 1px solid black;
    margin-top: 130px;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    .img_wrapper{
        div{
            position: relative;
            span{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }        
            img{
                height: 100%;
                width: 100%;
                position: inherit;
                top: 0px;
                border-radius: 10px;
            }
            .ant-upload-list-item-info:before {
                left: 0;
            }
            .ant-upload-list-item {
                border: 0;
            }
        }
    }
    .title_tag {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        font-family: Verdana;
        letter-spacing: 0.007em;
        margin: 0px;
    }
    .desc_tag {
        font-family: Verdana;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 247.03%;
        letter-spacing: 0.007em;
        color: #73828D;
    }
    .button_tag button{
        width: 150px;
        height: 48px;
        border-radius: 104px;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.007em;
    }

    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
        width: 242px;
        height: 242px;
        border: 1.5px solid rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        border-radius: 16px;
        margin-top: -90px;
    }

    .ant-upload-list-picture-card-container {
        width: 242px;
        height: 242px;
        border: 1.5px solid rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        border-radius: 16px;
        margin-top: -90px;
        background-color: #fff;
    }
}

.details_wrapper {
    width: 950px;
    height: 700px;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    font-family: Verdana;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 195%;
    letter-spacing: 0.007em;
    color: #8A96AB;
    padding: 40px;
    align-items: center;
}

.hideeyeicon .anticon.anticon-eye {
	display: none;
}

.ant-upload-list-picture-card-container Col{
    width: 612px;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card Col{
    width: 612px;
    height: 75px;
}

.ant-picker.date_wrapper {
    min-width: 100%;
}

.profile_details{
    tr{
        td{
            p{
                margin-bottom: 0;
                display: table;
                span{
                    padding-right: 20px;
                    display: table-cell;
                    vertical-align: middle;
                }
                ul{
                    margin-bottom: 10px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        td:first-child{
		    border-right: none !important;
        }
	}
}
