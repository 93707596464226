.main__login_wrapper {
    background-color: #F5F5F5;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .login_card_wrapper {
        display: flex;
        background-color: #fff;
        max-width: 612px;
        max-height: 365px;  
        width: 100%;
        height: 100%;
        padding: 40px 45px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        justify-content: center;
        align-items: center;
        input{
            font-size: 15px;
            font-weight: 500;
        }
        .ant-form {
            width: 100%;
        }
        .input_wrapper {
            height: 61px;
            width: 100%;
            .icon_wrapper {
                width: 25px;
            }
        }
        .login_button_wrapper.ant-btn-primary {
            width: 100%;
            height: 62px;
            background: $primary_color_BG;
            border-radius: $btn_border_radius;
            border: 1px solid $primary_color;
            color: $btn_font_color;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.007em;
            text-transform: uppercase;
            &.theme_type3:before,
            &.theme_type3:after {
                content: '';
                width: 100%;
                height: 3px;
                display: inline-block;
                position: absolute;
                top: -6px;
                left: 0;
                opacity: 1;
                background-color: #C7B985;
            }
            &.theme_type3:after {
                top: auto;
                bottom: -6px;
            }
        }
        .forgot_pass_wrapper {
            height: 20px;
            margin-top: 24px;
            max-width:  162.9px;
        }
    }

    @media screen and (max-width: 300px) {
        .login_card_wrapper {
            height: auto;
            padding: 15px;
            .ant-form-item {
                margin-bottom: 10px;
            }
            .input_wrapper {
                height: auto;
                .icon_wrapper {
                    width: 15px;
                }
            }
            .login_button_wrapper.ant-btn-primary {
                height: auto;
                font-size: 16px;
                line-height: 18px;
                padding: 5px 10px;
            }
            .forgot_pass_wrapper {
                text-align: center;
                width: 100%;
                margin: 5px 0 0;
            }
        }
    }
}

.main__forgot_pass_wrapper {
    background-color: #F5F5F5;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .login_card_wrapper {
        display: flex;
        background-color: #fff;
        max-width: 612px;
        max-height: 299px;  
        width: 100%;
        height: 100%;
        padding: 40px 45px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        justify-content: center;
        align-items: center;
        .ant-form {
            width: 100%;
        }
        .input_wrapper {
            height: 61px;
            width: 100%;
            .icon_wrapper {
                width: 25px;
            }
        }
        .login_button_wrapper.ant-btn-primary {
            width: 100%;
            height: 62px;
            background: $primary_color_BG;
            border-radius: $btn_border_radius;
            border: 1px solid $primary_color;
            color: $btn_font_color;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.007em;
            text-transform: uppercase;
            &.theme_type3:before,
            &.theme_type3:after {
                content: '';
                width: 100%;
                height: 3px;
                display: inline-block;
                position: absolute;
                top: -6px;
                left: 0;
                opacity: 1;
                background-color: #C7B985;
            }
            &.theme_type3:after {
                top: auto;
                bottom: -6px;
            }
        }
        .forgot_pass_wrapper {
            height: 20px;
            margin-top: 24px;
            max-width:  162.9px;
        }
    }
    @media screen and (max-width: 300px) {
        .login_card_wrapper {
            height: auto;
            padding: 15px;
            .ant-form-item {
                margin-bottom: 10px;
            }
            .input_wrapper {
                height: auto;
                .icon_wrapper {
                    width: 15px;
                }
            }
            .login_button_wrapper.ant-btn-primary {
                height: auto;
                font-size: 16px;
                line-height: 18px;
                padding: 5px 10px;
            }
            .forgot_pass_wrapper {
                text-align: center;
                width: 100%;
                margin: 5px 0 0;
            }
        }
    }
}

.main__reset_pass_wrapper {
    background-color: #F5F5F5;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .login_card_wrapper {
        display: flex;
        background-color: #fff;
        max-width: 612px;
        max-height: 299px;  
        width: 100%;
        height: 100%;
        padding: 40px 45px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        justify-content: center;
        align-items: center;
        .ant-form {
            width: 100%;
        }
        .input_wrapper {
            height: 61px;
            width: 100%;
            .icon_wrapper {
                width: 25px;
            }
        }
        .login_button_wrapper.ant-btn-primary {
            width: 100%;
            height: 62px;
            background: $primary_color_BG;
            border-radius: $btn_border_radius;
            border: 1px solid $primary_color;
            color: $btn_font_color;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.007em;
            text-transform: uppercase;
            &.theme_type3:before,
            &.theme_type3:after {
                content: '';
                width: 100%;
                height: 3px;
                display: inline-block;
                position: absolute;
                top: -6px;
                left: 0;
                opacity: 1;
                background-color: #C7B985;
            }
            &.theme_type3:after {
                top: auto;
                bottom: -6px;
            }
        }
        .forgot_pass_wrapper {
            height: 20px;
            margin-top: 24px;
            max-width:  162.9px;
        }
    }
    @media screen and (max-width: 300px) {
        .login_card_wrapper {
            height: auto;
            padding: 15px;
            .ant-form-item {
                margin-bottom: 10px;
            }
            .input_wrapper {
                height: auto;
                .icon_wrapper {
                    width: 15px;
                }
            }
            .login_button_wrapper.ant-btn-primary {
                height: auto;
                font-size: 16px;
                line-height: 18px;
                padding: 5px 10px;
            }
            .forgot_pass_wrapper {
                text-align: center;
                width: 100%;
                margin: 5px 0 0;
            }
        }
    }
}