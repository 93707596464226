.text_span {
	height: 20px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	left: 317px;
	color: #000000;
}

.extra_note {
	font-size: 12px;
	cursor: pointer;
	text-align: right;
	color: #274358;
}