.formulize-wrapper {
	position: relative;
}
.builder_wrapper {
	.ant-card-body {
		padding: 15px;
		.ant-form-item.ant-row {
			margin-bottom: 0;
		}
	}
}
.formulize-field-wrapper {
	overflow: auto;
	height: 40vh;
	.formulize-custom {
		width: 198px;
		&.dragging {
			width: 100%;
			background: rgba(0, 0, 0, 0.1);
			opacity: 0.5;
			white-space: nowrap;
		}
	}
}
.sticky_fields {
	position: relative;
	top: 0;
	overflow: auto;
}
.formulize-custom {
	color: #000 !important;
	border: 1px solid #c8c8c8;
	background-color: #fff;
	margin: 0;
	font-size: 12px;
	margin-bottom: 5px;
	border-radius: 30px;
	padding: 0px 10px !important;
	opacity: 0.7;
	line-height: 18px;
	text-emphasis: overflow;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
		opacity: 1;
	}
	&:active {
		width: 60px;
		// overflow: hidden;
		text-emphasis: overflow;
		white-space: nowrap;
	}
	display: inline-block !important;
}
.formulize-wrapper .formulize-text {
	opacity: 0;
	color: transparent;
	position: absolute;
	font-size: 0;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	text-indent: -10000px;
	z-index: 20;
}
.formulize-wrapper .formulize-container {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	padding: 0px;
	cursor: text;
	position: relative;
	line-height: 28px;
	min-height: 100px;
}
.formulize-wrapper .formulize-container .formulize-drag {
	display: inline;
	margin-top: -4px;
	padding: 12px 0;
	border-radius: 3px;
	vertical-align: middle;
	background-color: rgba(26, 94, 255, 0.15);
}
.formulize-wrapper .formulize-container .formulize-cursor {
	display: inline-block;
	vertical-align: middle;
	height: 16px;
	width: 3px;
	background-color: rgba(0, 0, 0, 0.6);
	animation: blink 0.5s ease alternate infinite;
}
.formulize-wrapper .formulize-container .formulize-item {
	display: inline-block;
	vertical-align: middle;
	padding: 0px 0px;
	word-break: break-all;
	margin: 6px;
	background-color: #ffffff;
	border-radius: 999px;
	color: #333333;
	position: relative;
	overflow: hidden;
}
.formulize-wrapper .formulize-container .formulize-operator {
	color: #f6474e;
}
.formulize-wrapper .formulize-container .formulize-bracket {
	background-color: transparent;
	color: #12c2b7;
}
.formulize-wrapper .formulize-alert {
	position: absolute;
	width: 100%;
	height: 0px;
	overflow: hidden;
	line-height: 28px;
	color: #777777;
	box-sizing: border-box;
	border: 1px solid #d8d8d8;
	border-top: 3px solid #ababab;
	border-right: 0;
	background-color: #fafafa;
	text-align: center;
	top: 0;
	right: 1px;
	z-index: 10;
}
.formulize-wrapper .formulize-alert.formulize-alert-good {
	color: $success_color;
	border-top-color: $success_color;
}
.formulize-wrapper .formulize-alert.formulize-alert-error {
	color: $danger_color;
	border-top-color: $danger_color;
}
.valid_always {
	.formulize-wrapper .formulize-alert.formulize-alert-error {
		color: $success_color;
		border-top-color: $success_color;
	}
}
.formulize-wrapper .formulize-prefix {
	color: rgba(0, 0, 0, 0.7);
}
.formulize-wrapper .formulize-suffix {
	color: rgba(0, 0, 0, 0.3);
}
.custom_higlight.ant-form-item-has-success .ant-form-item-control-input textarea {
	border-color: green;
	color: #000;
}
.custom_higlight.ant-form-item-has-error .ant-form-item-control-input textarea {
	color: #000;
}
@keyframes blink {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
