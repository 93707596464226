:root {
    --viewPrimaryBG: #233643;
    --viewPrimaryheaderText: #fff;
    --viewPrimaryColor: #233643;
    --viewPrimaryBorder: #797c81;
}

$viewPrimaryBG: var(--viewPrimaryBG);
$viewPrimaryColor: var(--viewPrimaryColor);
$viewPrimaryBorder: var(--viewPrimaryBorder);
$viewPrimaryheaderText: var(--viewPrimaryheaderText)