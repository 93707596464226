.viewCredebtor {
    font-size: 18px;
    margin-top: 15px;
    table {
      padding: 0;
      margin: -1rem 0 3rem;
      width: 100%;
      text-align: left;
      th,
      td {
        padding: 1rem 1rem;
        border: 1px solid #000;
        vertical-align: top;
        font-size: 13px;;
        line-height: 16px;
        letter-spacing: 0.007em;
        width: 20%;
        word-break: break-word;
    }
    th {
        background-color: #fff;
        font-weight: bold;
        color: #333;
    }
    td {
          color: #454545;
        background-color: #fff;
      }
      td.logo {
        vertical-align: middle !important;
        text-align: center;
        font-size: 40px !important;
        height: auto;
        display: table-cell;
      }
    }
  }